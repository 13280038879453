import { Component } from '@angular/core';

@Component({
  selector: 'app-titlecase-pipe',
  template: `
      <b>{{message | uppercase}}</b>
  `
})
export class TitleCasePipeComponent {
  public message = 'DETOXIFY';

}
