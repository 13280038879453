// Modules 3rd party
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// 404 page
import { PageNotFoundComponent } from './pages/not-found/not-found.component';

// Pages
import { HomeComponent } from './pages/home/home.component';
import { AuthComponent } from './pages/auth/auth.component';

// Components

// Protected
import { AuthGuardService } from '@shared';

// Routing
const appRoutes: Routes = [

  // Public pages
  { path: '', redirectTo: '/auth', pathMatch : 'full' },
  { path: 'home', component: HomeComponent , canActivate: [AuthGuardService]},
  { path: 'auth', component: AuthComponent },
  // Protected pages
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
