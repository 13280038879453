import { Component } from '@angular/core';
import {AlertService, AuthService} from '@shared';
import {AngularFireAuth} from '@angular/fire/auth';
import {Router} from '@angular/router';
import * as firebase from 'firebase/app';
import { MatSpinner } from '@angular/material';


@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent {
  user: any = {
    email: '',
    password: ''
  };
  loginInProgress = false;

  constructor(
    public afAuth: AngularFireAuth,
    private router: Router,
    private alert: AlertService
  ) {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        console.log('loggedin');
        this.router.navigateByUrl('/home');
      } else {
        console.log('not loggedin');
      }
    });
  }

  login(): void {
    this.loginInProgress = true;
    console.log(this.user);
    const that = this;
    this.afAuth.auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
      this.afAuth.auth.signInWithEmailAndPassword(this.user.email, this.user.password).then(function(result) {
        console.log(result);
        if (result['user']['email'] !== 'familyfirsttechnology@gmail.com') {
          firebase.auth().signOut();
          that.loginInProgress = false;
          that.alert.showToaster('Access Denied');
        } else {
          that.router.navigateByUrl('/home');
        }

      }).catch(function(error) {
        console.log(error);
        that.loginInProgress = false;
        that.alert.showToaster('Invalid Credentials');
      });
    }).catch(() => {
      that.loginInProgress = false;
      
    });

  }

}
