import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';

@Injectable()
export class AlertService {

  constructor(private snackBar: MatSnackBar) {}

  public showToaster(msg: string): void {
    const config = new MatSnackBarConfig();
      config.panelClass = ['background'];
      config.duration = 3000;
      let action = 'Ok'
    this.snackBar.open(msg, action, config);
  }
}
