// Modules 3rd party
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatButtonModule,
  MatCardModule,
  MatDialogModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatProgressBarModule
} from '@angular/material';

// Components
import {CardBlockComponent, ConfirmDialogComponent} from './card-block/card-block.component';
import {FormsModule} from '@angular/forms';
import { ClipboardModule } from 'ngx-clipboard';
@NgModule({
  declarations: [
    CardBlockComponent,
    ConfirmDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule, MatCardModule,
    MatListModule,
    ClipboardModule,
    MatDialogModule,
    MatButtonModule, MatInputModule, MatProgressBarModule,
    MatCardModule, MatIconModule,
  ],
  exports: [
    CardBlockComponent,
  ]
})
export class BlocksModule {
}
