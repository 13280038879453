export class Contact {
  constructor(
    public company: string,
    public firstname: string,
    public lastname: string,
    public address: string,
    public city: string,
    public province: string,
    public zipcode: string
  ) {  }
}
