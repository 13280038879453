import { Component } from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import * as firebase from 'firebase';
import {Router} from '@angular/router';
import {AlertService} from '@shared/services';

@Component({
  selector: 'app-home',
  templateUrl: 'home.component.html',
  styles: [`
  button {
    position: fixed;
    bottom: 70px;
    float: right;
    right: 10px;
    z-index: 10;
  }`]
})

export class HomeComponent {

  constructor(public afAuth: AngularFireAuth, router: Router, alertService: AlertService) {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        alertService.showToaster('Login Successful. Welcome.');
      } else {
        router.navigateByUrl('/auth');
      }
    });

  }


  public onToTop(): void {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

}
