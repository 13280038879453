import { Component, OnInit } from '@angular/core';
import * as firebase from 'firebase';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  public ngOnInit(): void {
    var firebaseConfig = {
      apiKey: "AIzaSyBsMLtUmdf1dmqvsO-A9Yq4p5X4Qbd-D90",
      authDomain: "detoxify-porn-blocker.firebaseapp.com",
      databaseURL: "https://detoxify-porn-blocker.firebaseio.com",
      projectId: "detoxify-porn-blocker",
      storageBucket: "detoxify-porn-blocker.appspot.com",
      messagingSenderId: "945871537236",
      appId: "1:945871537236:web:cbe0e6022f48e3e67f59c1"
    };

    firebase.initializeApp(firebaseConfig);
  }
}
