import {Component, Inject} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import * as moment from 'moment';
import {AlertService} from '@shared/services';
import * as firebase from 'firebase/app';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
  selector: 'app-card-block',
  templateUrl: './card-block.component.html',
  styleUrls: ['./card-block.component.scss']
})
export class CardBlockComponent {
  angularImage: string;
  searchValue: string;
  searchResult = false;
  user: any;
  pin: any;
  apps: any = {};
  domains: any= {};


  constructor(private firestore: AngularFirestore,
              public dialog: MatDialog,
              private alert: AlertService) {
    this.angularImage = '/assets/img/angular2.png';
  }

  search(e) {
    this.searchResult = false;
    if (e === 'click' || e.keyCode === 13) {
        console.log(this.searchValue);
        const that = this;
        this.pin = {};
        this.user = {};
        this.fetchUser();
        this.fetchUserByUserId();
    }

  }
  fetchUser() {
    const that = this;
    this.firestore.collection('users').ref
      .where('email', '==', that.searchValue)
      .get()
      .then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, ' => ', doc.data());
          that.user = doc.data();
          that.user['did'] = doc.id;
          that.fetchPin();
          that.fetchBlockedApps();
          that.fetchBlockedDomains();
        });
      })
      .catch(function(error) {
        //that.alert.showToaster('No records found');
      });
  }

  fetchUserByUserId() {
    const that = this;
    this.firestore.collection('users').ref
      .where('userId', '==', that.searchValue)
      .get()
      .then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, ' => ', doc.data());
          that.user = doc.data();
          that.user['did'] = doc.id;
          that.fetchPin();
          that.fetchBlockedApps();
          that.fetchBlockedDomains();
        });
      })
      .catch(function(error) {
        //that.alert.showToaster('No records found');
      });
  }

  openDialog(text, type, days): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
      data: {name: text}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (type === 'randomize' && result === 'yes') {
        const pin = Math.floor(1000 + Math.random() * 9000).toString();
        const that = this;
        if (this.pin['did'] === 'notset') {
          console.log(that.user.userId);
          this.firestore.collection('pin').doc(that.user.userId).set({
            pin: pin,
            userId: that.user.userId
          }).then((data) => {
            that.fetchPin();
            console.log(data);
          }).catch((err) => {
            console.log(err);
          });
        } else {
          this.firestore.collection('pin').doc(this.pin['did']).update({pin: pin})
              .then(() => {
                console.log('Updated');
                that.fetchPin();
              }).catch((err) => {
            console.log(err);
          });
        }
      } else if (type === 'trial' && result === 'yes') {
        const that = this;
        let cDate = moment();
        if (this.user['trialEndDate']) {
          cDate = moment(this.user['trialEndDate']['seconds'] * 1000);
        }
        const newTrial = cDate.add(days, 'days').toDate();
        console.log(newTrial);
        this.firestore.collection('users').doc(this.user['did']).update({trialEndDate: newTrial})
          .then(() => {
            console.log('Updated');
            that.fetchUser();
          }).catch((err) => {
          console.log(err);
        });
      } else if (type === 'blockedApps' && result === 'yes') {
        console.log(days);
        this.apps['lockedApps'] = this.apps['lockedApps'].filter((v) => {
          return v !== days;
        });
        const that = this;
        this.firestore.collection('appLock').doc(this.apps['did']).update({lockedApps: this.apps['lockedApps']})
          .then(() => {
            console.log('Updated');
            that.fetchBlockedApps();
          }).catch((err) => {
          console.log(err);
        });
      } else if (type === 'blockedDomain' && result === 'yes') {
        console.log(days);
        this.domains['blockedDomains'] = this.domains['blockedDomains'].filter((v) => {
          return v !== days;
        });
        const that = this;
        this.firestore.collection('customBlacklist').doc(this.domains['did']).update({blockedDomains: this.domains['blockedDomains']})
          .then(() => {
            console.log('Updated');
            that.fetchBlockedDomains();
          }).catch((err) => {
          console.log(err);
        });
      }
    });
  }

  fetchBlockedApps() {
    const that = this;
    this.firestore.collection('appLock').ref
      .where('userId', '==', that.user['userId'])
      .get()
      .then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          console.log(doc.id, ' => ', doc.data());
          that.apps['lockedApps'] = doc.data()['lockedApps'];
          that.apps['did'] = doc.id;
        });
      })
      .catch(function(error) {
      });
  }
  fetchBlockedDomains() {
    const that = this;
    this.firestore.collection('customBlacklist').ref
      .where('userId', '==', that.user['userId'])
      .get()
      .then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          console.log(doc.id, ' => ', doc.data());
          that.domains['blockedDomains'] = doc.data()['blockedDomains'];
          that.domains['did'] = doc.id;
        });
      })
      .catch(function(error) {
      });
  }
  removeApp(app) {
    this.openDialog('Remove the app.', 'blockedApps', app);
  }

  removeDomain(app) {
    this.openDialog('Remove the domain.', 'blockedDomain', app);
  }

  fetchPin() {
    console.log("Pin fetching");
    const that = this;
    this.firestore.collection('pin').ref
      .where('userId', '==', that.user['userId'])
      .get()
      .then(function(querySnapshot) {
        console.log(querySnapshot.empty);
        console.log("here");
        if (querySnapshot.empty){
          that.pin = {};
          that.pin['did'] = 'notset';
          that.pin['pin'] = 'notset';
          that.searchResult = true;
        } else {
          querySnapshot.forEach(function (doc) {
            console.log(doc);
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, ' => ', doc.data());
            that.pin = doc.data();
            that.pin['did'] = doc.id;
            that.searchResult = true;
          });
        }
      })
      .catch(function(error) {
        console.log(error);
        that.alert.showToaster('No records found');
        
      });
  }
  extendTrial(days) {
    this.openDialog('Extend the trial period.', 'trial', days);

  }

  randomize() {
    this.openDialog('Randomize the pin.', 'randomize', 0);
  }

  formatDate(date) {
    if (date) {
      return moment(date['seconds'] * 1000).format('MMM Do YY');
    }
    return '';
  }

}
@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'ConfirmDialog.html',
})
export class ConfirmDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}
